import dynamic from 'next/dynamic';

const pages = {
  'home.HomePage': dynamic(() => import('./HomePage')),
  'flex.FlexPage': dynamic(() => import('./FlexPage')),
  'forms.FormPage': dynamic(() => import('./FormPage')),
  PasswordProtectedPage: dynamic(() => import('./PasswordProtectedPage')),
};

export type Pages = keyof typeof pages;

export default pages;
