import type { PageData } from '@/types';
import { getFooter, getHeader, getMenu, getPage, WagtailApiResponseError } from '@api/wagtail';
import LazyViews from '@views/LazyViews';
import { GetStaticPaths, GetStaticProps } from 'next';

const isProd = process.env.NODE_ENV === 'production';

type Props = {
  pageData: PageData;
};

function CatchAllPage(props: Props) {
  const { pageData } = props;

  const Component = LazyViews[pageData.meta.type];
  if (!Component) {
    return <h1>Component {pageData.meta.type} not found</h1>;
  }

  // TODO: Fix Typing
  // @ts-ignore
  return <Component {...pageData} />;
}

export default CatchAllPage;

export const getStaticProps: GetStaticProps = async context => {
  const { params } = context;
  let path = params?.path ?? [];
  if (Array.isArray(path)) path = path.join('/');

  try {
    const [{ json: pageData }, { json: footerData }, { json: headerData }, { json: menuData }] =
      await Promise.all([getPage(path), getFooter(), getHeader(), getMenu()]);

    return {
      props: { pageData, footerData, headerData, menuData },
      revalidate: 3600,
    };
  } catch (err) {
    if (!(err instanceof WagtailApiResponseError)) {
      throw err;
    }

    if (!isProd && err.response.status >= 500) {
      const html = await err.response.text();
      return {
        props: {
          componentName: 'PureHtmlPage',
          componentProps: { html },
        },
      };
    }

    if (err.response.status >= 500) {
      throw err;
    }
    console.error(err);
    return { notFound: true };
  }
};

// Determine which pages should be pre-rendered at build time
export const getStaticPaths: GetStaticPaths = () => {
  // const { json: data } = await getAllPages();

  // let htmlUrls = data.items.map((x) => x.relativeUrl);
  // htmlUrls = htmlUrls.filter((x) => x);
  // htmlUrls = htmlUrls.map((x) => x.split("/"));
  // htmlUrls = htmlUrls.map((x) => x.filter((y) => y));
  // htmlUrls = htmlUrls.filter((x) => x.length);

  // const paths = htmlUrls.map((x) => ({ params: { path: x } }));

  return {
    paths: [],
    fallback: 'blocking',
  };
};
